body {
  font-family: 'Roboto', sans-serif;
}

.fade-in-element {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #fff;
  top: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7%;
  box-sizing: border-box;
  gap: 20px;
}

.fade-in-element.fade-in {
  opacity: 1;
}

.fade-in-element h3 {
  font-size: 42px;
  font-weight: 500;
}

.fade-in-element .text {
  max-width: 800px;
}

.fade-in-element h5 {
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .fade-in-element {
    flex-direction: column
  }
}